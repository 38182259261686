import type { RumPublicApi } from '@datadog/browser-rum-core'
import { pinoRootLogger } from '@utilities/unsafe'
import { get, proxied } from 'radash'
import { addDDError } from './actions'

declare global {
  interface Window {
    DD_RUM: RumPublicApi
  }
}

export const withDDReady = (fn: (dd: RumPublicApi) => void) => {
  try {
    if (typeof window !== 'undefined') {
      const globalDD = get<RumPublicApi>(window, 'DD_RUM', proxiedDatadog)
      const onReadyFn = get(window, 'DD_RUM.onReady', (fn: Function) => {})
      onReadyFn(() => fn(globalDD))
    } else {
      pinoRootLogger.error('Datadog not available as not in Browser')
    }
  } catch (err) {
    addDDError(err)
    pinoRootLogger.error('Failed to invoke datadog function', err)
  }
}

const proxiedDatadog = proxied((property) => {}) as unknown as RumPublicApi
