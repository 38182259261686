export const createTypedNoOp = <T>() =>
  new Proxy(
    {},
    {
      apply(target, thisArg, argArray) {
        // biome-ignore lint/suspicious/noConsoleLog: ignoring rule for proxy
        console.log(`[Proxy] Invocation of ${target} with ${argArray} `)
      },
    },
  ) as T
