import { getCNFContext } from '@evenfinancial/app-utils'

// Note: this is a hack to support both client-side web-component and server
// side NextJS code. If `getCNFContext` isn't available then we're in
// client-side and the templating will be replaced by the web-component host. I
// really don't like how this couples us so tightly to that code for the record.
let base = '<%= it.cnfBaseUrl() %>'
try {
  base = getCNFContext().use().context.request.cnfBaseUrl
} catch (e) {
  // TODO: I don't feel good about swallowing the error here, but at the same
  // time we wouldn't want to log anything  in the client. This whole approach
  // smells to me, but is good enough for now. 🤷😬 Here's hoping someone finds
  // something better later.
}

export const rudderstackPaths = {
  scripts: '/cnf/rsproxy/v3',
  plugins: '/cnf/rsproxy/v3/modern/plugins',
  dataplane: '/cnf/rs',
  controlplane: '/cnf/rsproxy',
}

export const rudderstackUrls = {
  scripts: `${base}${rudderstackPaths.scripts}`,
  plugins: `${base}${rudderstackPaths.plugins}`,
  dataplane: `${base}${rudderstackPaths.dataplane}`,
  controlplane: `${base}${rudderstackPaths.controlplane}`,
}
