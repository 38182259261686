'use client'
import { isRudderstackGlobalInjected } from './check'
import { injectScript } from './documentHelpers'

declare global {
  interface Window {
    rudderAnalyticsBuildType: string
  }
}

export const LazyRudderstackScript = ({
  rudderStackScriptsUrl,
}: { rudderStackScriptsUrl: string }) => {
  if (!isRudderstackGlobalInjected()) {
    console.warn('WARNING: Rudderstack not initialized')
    return null
  }
  if (!('requestIdleCallback' in window)) {
    // iOS can't hang
    // @ts-ignore
    window.requestIdleCallback = (fn: Function) => {
      setTimeout(fn, 100)
    }
  }
  requestIdleCallback(() => {
    const rsScriptPath = [
      rudderStackScriptsUrl,
      window.rudderAnalyticsBuildType,
      'rsa.min.js',
    ].join('/')
    injectScript(rsScriptPath, {
      location: 'head',
      id: 'rudderstack-script',
    })
  })
  return null
}
