import { camel } from 'radash'
import { type CNF_ROLES, CNF_ROLE_ATTRIBUTE } from './constants'

export type DataAttributes = { [x in `data-${string}`]: string }

export const findClosestElementWithRole = (
  element: EventTarget | null,
  role: keyof typeof CNF_ROLES,
) => {
  if (!(element instanceof HTMLElement)) return undefined
  const closestElement = element.closest(`[${CNF_ROLE_ATTRIBUTE}=${role}]`)
  if (!closestElement) return undefined
  return closestElement
}

export const getCnfDataAttrFromElement = (element: Element) => {
  const attributes = element.getAttributeNames().filter((x) => x.startsWith('data-cnf'))
  const resolvedDetails = Object.fromEntries(
    attributes.map((x) => [
      camel(x.replaceAll('data-cnf-', '')),
      element.getAttribute(x),
    ]),
  )

  return resolvedDetails
}

/**
 * Returns all children components with the provided CNF ROLE
 */
export const getChildrenHavingRole = (role: keyof typeof CNF_ROLES, parent?: Element) => {
  const base = parent ?? document.body
  const allElements = Array.from(base.querySelectorAll(`[data-cnf-role=${role}]`))
  return allElements
}

/**
 * Resolves the attributes matching the passed Role, even if they are in a wrapper element
 * @param element
 * @param role
 */
export const findClosestAttributes = (
  element: EventTarget | null,
  role: keyof typeof CNF_ROLES,
) => {
  const closestElement = findClosestElementWithRole(element, role)
  if (!closestElement) return {}

  const index = getChildrenHavingRole(role).indexOf(closestElement)

  const resolvedDetails = getCnfDataAttrFromElement(closestElement)
  return {
    ...resolvedDetails,
    position: index,
  }
}
