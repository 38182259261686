'use client'

import { createContext } from 'react'

export type TrackEvent = (
  event: string,
  properties?: Record<string, string | number | undefined>,
) => void

export type SegmentContext = {
  trackEvent: TrackEvent
  pageView: (path: string, search: string) => void
}

export const SegmentContext = createContext<SegmentContext | undefined>(undefined)
