'use client'

import { useEffect, useState } from 'react'
import { LazyRudderstackScript } from './Rudderstack.client'
import { isRudderstackGlobalInjected } from './check'
import { globalSnippet } from './snippets/rsGlobalSetup'
import { rudderstackUrls } from './urls'

// TODO: Find a better way to handle client-side only code like for web
// components. The script render above from RudderstackGlobal
// works just fine when rendered on the server by a NextJS
// application, but when used in a web hook the script tag never executes
// when added that way. Had to use a useEffect and useState as a hack. 🤷🤢
export const RudderstackGlobalForWebComponents = () => {
  const [rudderstachGlobalInjected, setRudderStackGlobalInjected] = useState(
    isRudderstackGlobalInjected(),
  )
  useEffect(() => {
    // TODO: Get Alex Whiteside to think about this and do something better
    // sometimes we embed web components into pages that already have rudderstack
    // global injected by server side rendering like if you render a web
    // with rudderstack into a NextJS page that uses this implementation of
    // rudderstack as well component, so we shouldn't inject it again
    if (!rudderstachGlobalInjected) {
      const script = document.createElement('script')
      script.textContent = globalSnippet
      document.body.appendChild(script)
      setRudderStackGlobalInjected(true)
      return () => {
        document.body.removeChild(script)
      }
    }
    // TODO: Don't have code just to make a linter happy 🤦. Right now I just
    // need this to build in CI without errors. 😓
    return () => {
      /* noop */
    } // make linter happy 😬
  }, [rudderstachGlobalInjected])
  return (
    rudderstachGlobalInjected && (
      <LazyRudderstackScript rudderStackScriptsUrl={rudderstackUrls.scripts} />
    )
  )
}
