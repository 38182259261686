'use client'

import { get } from 'radash'
import { useCallback } from 'react'
import { getWebClient } from '../clients/web/client'
import {
  findClosestElementWithRole,
  getChildrenHavingRole,
  getCnfDataAttrFromElement,
} from '../events'

/**
 *  This component is can be placed after the last of a list of products (but within the list),
 *  no matter how they're rendered, and will fire a tracking event with their details whenever the list changes.
 * @constructor
 */
export const ListViewEventTrigger = () => {
  const ref = useCallback((element: HTMLSpanElement) => {
    const listElement = findClosestElementWithRole(element, 'list')
    if (!listElement) return
    const listAttr = getCnfDataAttrFromElement(listElement)
    listAttr.filters = JSON.parse(get(listAttr, 'filters'))
    const products = getChildrenHavingRole('product', listElement).map(
      getCnfDataAttrFromElement,
    )
    getWebClient().track('Product List Filtered', { ...listAttr, products })
  }, [])

  return <span style={{ display: 'contents', position: 'absolute' }} ref={ref} />
}
