import { hasWindow } from 'std-env'

interface InjectOptions {
  location: 'head' | 'body'
  id?: string
}

export const injectScript = (
  src: string,
  { location, id }: InjectOptions = { location: 'head' },
) => {
  if (id && document.getElementById(id)) return
  const script = document.createElement('script')
  script.src = src
  if (id) script.id = id
  script.async = true
  document[location].appendChild(script)
}

const determineOrigin = () => {
  if (!hasWindow) return ''
  if (['localhost', '127.0.0.1', '.local'].some((x) => window.origin.includes(x))) {
    return 'https://dev.fiona.com'
  }
  return window.origin
}
export const urlFromOrigin = (path: string) => {
  const origin = determineOrigin()
  const pathPrefix = path.startsWith('/') ? '' : '/'
  return [origin, pathPrefix, path].join('')
}
