'use client'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { verifySegmentWriteKey } from './utils/server'

/**
 * Client Actions for making Segment Analytics calls.
 *
 * All functions in this file will be executed on the client side even if imported
 * and called on the server side.
 *
 * @param segmentWriteKey - The Segment write key to use for tracking.
 * @param event - The name of the event to send to Segment.
 * @param properties - Optional additional properties to send to Segment.
 *
 * **note: channel, zone, app, deviceId (as anonymousId), currentPageUrl are added implicitly to the properties**
 */
export function trackEngagementClient(
  segmentWriteKey: string | undefined,
  event: string,
  properties: {},
) {
  const analytics = initClient(segmentWriteKey)
  return analytics.track(event, properties)
}

let analyticsInstance: AnalyticsBrowser | null = null

export function initClient(writeKey: string | undefined) {
  const verifiedKey = verifySegmentWriteKey(writeKey)

  if (!analyticsInstance) {
    analyticsInstance = AnalyticsBrowser.load({ writeKey: verifiedKey })
  }

  return analyticsInstance
}

// Function to reset the analytics instance (for testing purposes)
export function resetAnalyticsInstance() {
  analyticsInstance = null
}
