import { sift } from 'radash'
import type { EcommerceEvents } from '../ecommerce'
import type { GenericEvents } from '../generic'

const TRIGGER_ATTRIBUTE = 'data-cnf-event-trigger'

/**
 * For a given event, returns the data attributes object to pass to attributes prop
 * @param event
 */
export const asEventTrigger = (event: keyof EcommerceEvents | keyof GenericEvents) => {
  return { [TRIGGER_ATTRIBUTE]: event }
}

/**
 * For a given element (usually sourced from a click event),
 * resolve the closest clickable element that has a trigger attribute, or return undefined
 */
export const getEventTrigger = (el: EventTarget | GenericEvents | null) => {
  if (!(el instanceof Element)) return undefined
  const triggerCandidates = sift([el, el.closest('a')])
  return triggerCandidates
    .find((x) => x.attributes.getNamedItem(TRIGGER_ATTRIBUTE)?.value)
    ?.attributes?.getNamedItem(TRIGGER_ATTRIBUTE)?.value
}
