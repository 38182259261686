'use client'

import React, { useEffect } from 'react'
import { useSegment } from './useSegment'

export const WebComponentSegmentPageViewMonitor: React.FC = () => {
  const lastTrackedPage = React.useRef('')
  const { pageView } = useSegment()

  useEffect(() => {
    const path = window.location.pathname
    const search = new URLSearchParams(window.location.search)
    const page = `${path}${search.toString()}`

    if (lastTrackedPage.current !== page) {
      lastTrackedPage.current = page
      pageView(path, search.toString())
    }
  }, [pageView])

  return null
}
