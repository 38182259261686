'use client'
import { usePathname, useSearchParams } from 'next/navigation'
import { useCallback } from 'react'

export const useSearchParamLinkGenerator = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  return useCallback(
    (record: Record<string, string | null>) => {
      const params = new URLSearchParams(searchParams)
      Object.entries(record).forEach(([key, value]) => {
        value != null ? params.set(key, value) : params.delete(key)
      })
      params.sort()

      return [pathname, decodeURI(params.toString())].join('?')
    },
    [searchParams, pathname],
  )
}

export const useLinkGeneratorWithParameters = (record: Record<string, string | null>) => {
  const generator = useSearchParamLinkGenerator()
  return generator(record)
}

export const useLinkGenerator = () => {
  const pathname = usePathname()
  return useCallback(
    (record: Record<string, string | null>) => {
      const params = new URLSearchParams()
      Object.entries(record).forEach(([key, value]) => {
        value && params.set(key, value)
      })
      params.sort()
      return [pathname, params.toString()].join('?')
    },
    [pathname],
  )
}
