import { AnalyticsBrowser } from '@segment/analytics-next'

export const loadSegment = (writeKey: string) => {
  try {
    return AnalyticsBrowser.load({
      writeKey,
    })
  } catch (error) {
    console.error(error)
    return undefined
  }
}
