import pino from 'pino'

// custom levels are necessary for proper datadog logging
// without modifying the log levels, datadog will track everything as INFO
const levels = {
  emerg: 80,
  alert: 70,
  crit: 60,
  error: 50,
  warn: 40,
  notice: 30,
  info: 20,
  debug: 10,
  trace: 0,
}

/**
 * Root configuration logger - can be imported into any file and reused for logging details
 * Invoking pinoRootLogger.child({...props}) creates pre configured child loggers with any necessary one-off props
 */
export const pinoRootLogger = pino({
  // this can be adjusted for greater depth of logs
  level: 'info',
  customLevels: levels,
  useOnlyCustomLevels: true,
  formatters: {
    level: (label) => {
      return { level: label.toUpperCase() }
    },
  },
})
