'use client'

import { type PropsWithChildren, useCallback, useEffect, useMemo, useRef } from 'react'

import { SegmentContext, type TrackEvent } from './SegmentContext'
import { loadSegment } from './segment'

import type { AnalyticsBrowser } from '@segment/analytics-next'

type SegmentProviderProps = PropsWithChildren<{
  writeKey: string
  commonProps?: Record<string, string | number | undefined | object>
}>

type EventQueued =
  | {
      type: 'track'
      args: Parameters<TrackEvent>
    }
  | {
      type: 'page'
      args: [string, string]
    }

export const SegmentProvider = ({
  children,
  writeKey,
  commonProps,
}: SegmentProviderProps) => {
  const analytics = useRef<AnalyticsBrowser | undefined>(undefined)
  // This queue is used to batch events if the analytics library is not loaded
  const eventsQueue = useRef<Array<EventQueued>>([])

  const trackEvent = useCallback(
    (event: string, properties?: Record<string, string | number | undefined>) => {
      if (analytics.current) {
        analytics.current.track(event, {
          ...commonProps,
          ...properties,
        })
      } else {
        eventsQueue.current.push({
          type: 'track',
          args: [event, properties],
        })
      }
    },
    [commonProps],
  )

  const pageView = useCallback(
    (path: string, search: string) => {
      if (analytics.current) {
        analytics.current.page(path, {
          path,
          search,
          ...commonProps,
        })
      } else {
        eventsQueue.current.push({
          type: 'page',
          args: [path, search],
        })
      }
    },
    [commonProps],
  )

  const flushEvents = useCallback(() => {
    if (analytics.current) {
      eventsQueue.current.forEach(({ type, args }) => {
        if (type === 'page') {
          pageView(...args)
        } else {
          trackEvent(...args)
        }
      })

      eventsQueue.current = []
    }
  }, [trackEvent, pageView])

  useEffect(() => {
    if (!analytics.current) {
      const segment = loadSegment(writeKey)

      analytics.current = segment

      if (analytics.current) {
        flushEvents()
      }
    }
  }, [writeKey, flushEvents])

  useEffect(() => {
    if (!commonProps?.channel) {
      console.warn('Missing channel on segment integration')
    }

    if (!commonProps?.zone) {
      console.warn('Missing zone on segment integration')
    }

    if (!commonProps?.app) {
      console.warn('Missing app on segment integration')
    }
  }, [commonProps])

  const value = useMemo(() => ({ trackEvent, pageView }), [trackEvent, pageView])

  return <SegmentContext.Provider value={value}>{children}</SegmentContext.Provider>
}
