import { useContext } from 'react'
import { SegmentContext } from './SegmentContext'

export const useSegment = () => {
  const segmentContext = useContext(SegmentContext)

  if (!segmentContext) {
    throw new Error('useSegment must be used within a SegmentProvider')
  }

  return segmentContext
}
