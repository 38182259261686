'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'
import { useSegment } from './useSegment'

export function SegmentPageViewMonitor() {
  const prevPageWithSearch = useRef<string | undefined>(undefined)
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { pageView } = useSegment()

  useEffect(() => {
    const pageWithSearch = `${pathname}${searchParams.toString()}`

    if (prevPageWithSearch.current !== pageWithSearch) {
      prevPageWithSearch.current = pageWithSearch
      pageView(pathname, searchParams.toString())
    }
  }, [pathname, searchParams, pageView])

  return null
}
