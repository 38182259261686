'use client'
import { useEffect } from 'react'
import { findClosestAttributes, getEventTrigger } from '../events'
import type { Product } from '../events'

export const useEventTrigger = (
  callback: (event: string, product: Partial<Product>) => void,
) => {
  useEffect(() => {
    const listener: EventListener = (evt) => {
      const trigger = getEventTrigger(evt.target)
      if (!trigger) return
      const product = findClosestAttributes(evt.target, 'product')
      callback(trigger, product)
    }
    document.body.addEventListener('click', listener)
    return () => document.body.removeEventListener('click', listener)
  }, [callback])
}
