'use client'
import { defu } from 'defu'
import { usePathname, useSearchParams } from 'next/navigation'
import { shake, sift } from 'radash'
import { type PropsWithChildren, createContext, useContext, useEffect } from 'react'
import { addDDError } from '../datadog/index'
import { trackEngagementClient } from '../segment/actions/client'
import { getWebClient } from './clients'
import { useEventTrigger } from './react'

interface AnalyticsContext {
  experience: string
  /**
   * The description of the currently visible portion of the  experience, if applicable.
   * E.g. the Question screen in search, or the Relevant Offers screen in compare.
   * Overrides any Screen set by Parent.
   */
  screen?: string
  /**
   *  The currently active state, if applicable, such as 'Error', 'No Offers', 'Page 4'.
   *  Overrides any State set by Parent.
   */
  state?: string
  /**
   * A nested hierarchy that's appended to (think breadcrumbs), to denote its approximate location. Separated by
   */
  segment: []
  segmentWriteKey?: string
  analyticsDefaultProperties?: Record<string, string | number | undefined>
}

const Context = createContext<AnalyticsContext>({
  experience: 'CNF',
  segment: [],
  analyticsDefaultProperties: {},
})

export const AnalyticsRoot = ({
  children,
  experience,
  segmentWriteKey,
  analyticsDefaultProperties = {},
}: PropsWithChildren<
  Pick<AnalyticsContext, 'experience' | 'segmentWriteKey' | 'analyticsDefaultProperties'>
>) => {
  useEventTrigger((event, product) => {
    // hackhack - wedged in here for now to get the segment analytics working
    trackEngagementClient(segmentWriteKey, event, {
      ...analyticsDefaultProperties,
      ...product,
    })
  })

  return (
    <Context.Provider
      value={{ experience, segment: [], segmentWriteKey, analyticsDefaultProperties }}
    >
      {children}
    </Context.Provider>
  )
}

export const AnalyticsBoundary = ({
  children,
  segment,
  ...rest
}: PropsWithChildren<Omit<AnalyticsContext, 'segment'> & { segment?: string }>) => {
  const current = useContext(Context)
  const value = defu(current, rest, { segment: sift([...current.segment, segment]) })
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export const useAnalyticsContext = () => {
  const properties = useContext(Context)
  return shake({ ...properties, segment: properties.segment.join(' > ') })
}

export const PageViewMonitor = () => {
  const path = usePathname()
  const search = useSearchParams()
  const context = useAnalyticsContext()
  useEffect(() => {
    const page = `${path}?${search.toString()}`
    const view = search.get('view')
    const client = getWebClient()
    if (client) {
      try {
        client?.page(page, { ...context, view })
      } catch (err) {
        addDDError(err)
      }
    }
  }, [path, search, context])

  return <></>
}
