/**
 * Be careful, this file contains html injected code, which is run on the client outside of React.
 * Your IDE may not recognize usage, but take care in modifying.
 *
 */

import type { LoadOptions } from '@rudderstack/analytics-js'
import { rudderstackUrls } from '../urls'

const writeKeyGlobal = process.env.RS_WRITE_KEY
  ? `window.rudderAnalyticsWriteKey = '${process.env.RS_WRITE_KEY}'`
  : ''

// language=javascript
const originSnippet = `const determineOrigin = ()=>{
  if(['localhost', '127.0.0.1', '.local'].some(x=> window.origin.includes(x))){
    return 'https://dev.fiona.com'
  }
  return window.origin
}
 const urlFromOrigin = (path) => {
  const origin = determineOrigin()
  const pathPrefix = path.startsWith('/') ? '' : '/'
  return [origin, pathPrefix, path].join('')
}`

const loadOptions: LoadOptions = {
  useBeacon: true,
  plugins: ['BeaconQueue'],
  logLevel: 'INFO',
  sameSiteCookie: 'Lax',
  secureCookie: true,
  sameDomainCookiesOnly: true,
  loadIntegration: false,
}

// language=javascript
const loadSnippet = `
	
	window.rudderanalyticsOptions = JSON.parse('${JSON.stringify(loadOptions)}')
	window.rudderanalytics.load(window.rudderAnalyticsWriteKey, '${
    rudderstackUrls.dataplane
  }', {
      ...window.rudderanalyticsOptions,
      pluginsSDKBaseURL:'${rudderstackUrls.plugins}',
      configUrl: '${rudderstackUrls.controlplane}'
    })`
// language=javascript
export const globalSnippet = `window.rudderanalytics = []
var methods = [
  'setDefaultInstanceKey',
  'load',
  'ready',
  'page',
  'track',
  'identify',
  'alias',
  'group',
  'reset',
  'setAnonymousId',
  'startSession',
  'endSession',
]
for (var i = 0; i < methods.length; i++) {
  var method = methods[i]
  window.rudderanalytics[method] = (function (methodName) {
    return function () {
      window.rudderanalytics.push(
        [methodName].concat(Array.prototype.slice.call(arguments)),
      )
    }
  })(method)
}
// Set build type
window.rudderAnalyticsBuildType = 'legacy'
try {
  new Function('return import("")')
  window.rudderAnalyticsBuildType = 'modern'
} catch (e) {}
${originSnippet}
${writeKeyGlobal}
${loadSnippet}
`
